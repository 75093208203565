import * as React from "react";
import PublicOffer from "../components/publicCondition/PublicOffer";
import Layout from "../components/Layout/Layout";

const publicCondition = () => {
  return (
    <Layout>
      <PublicOffer />
    </Layout>
  );
};

export default publicCondition;
