export const publicConditionObjects = [
  "BasicConditionsText",
  "ProcedureForTheProvisionOfServicesText",
  "CalculationsText",
  "PersonalDataText",
  "ValidityOfTheContractAndLiabilityText",
  "OtherConditionsText",
  "DetailsOfTheContractorText",
];

export const preparationForTestsArray = ["PreparationForTestsPart1"];
