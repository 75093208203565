import * as React from "react";
import { injectIntl } from "gatsby-plugin-intl";
import parse from "html-react-parser";
import { publicConditionObjects } from "../../utils/publicConditionObjects";

// @ts-ignore
const PublicOffer = ({ intl }) => {
  return (
    <div className="grid  lg:justify-center sm:justify-left xs:justify-center  bg-[#FAFAFA]  ">
      <div className="flex justify-center items-center  xl:max-w-[1200px] xl:w-[1200px] lg:max-w-[1000px] lg:w-[1000px] sm:max-w-[680px] sm:w-[680px] xs:max-w-[300px] xs:w-[300px] ">
        <div className="xl:w-full mb-10 mt-5 flex flex-col   px-2  ">
          <p className=" lg:text-4xl md:text-2xl xs:text-[1.4rem] font-['gabdugi'] text-[#DA6A00] font-semibold  my-[1rem] md:font-bold sm:flex ">
            {parse(
              intl.formatMessage({
                id: "PublicCondition.PublicConditionHeader",
              })
            )}
          </p>
          <div className=" flex flex-col mb-3 xl:w-full justify-start items-start">
            <div className=" font-['gabdugi'] text-xl text-justify ">
              {parse(
                intl.formatMessage({
                  id: "PublicCondition.PublicConditionsText",
                })
              )}
            </div>
            {publicConditionObjects.map((conditions) => (
              <div className="mt-4 font-['gabdugi'] text-xl text-justify ">
                {parse(
                  intl.formatMessage({ id: `PublicCondition.${conditions}` })
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(PublicOffer);
